import React from "react"
import cx from "classnames"
import CmsText from "../../cms/cms-text/cms-text"

//Styles
import "./description-block.scss"

const DescriptionBlock = props => {
  const componentProps = props.blok ? props.blok : props.prop

  return (
    <div
      className={cx(
        "description-block",
        {
          "description-block__content--padding-top": componentProps.paddingTop,
        },
        {
          "description-block__content--padding-bottom":
            componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="description-block__content">
          <div className="description-block__content--item--header">
            {componentProps.heading}
          </div>
          {componentProps.texts.map((item, index) => (
            <div key={index} className="description-block__content--item">
              {item.component === "link" ? (
                <a
                  href={item.link.cached_url}
                  className="description-block__content--item--link"
                >
                  {item.linkText}
                </a>
              ) : (
                <CmsText
                  color={item.color ? item.color : "black"}
                  text={item.text}
                  size={item.size}
                  tag={item.tag ? item.tag : "p"}
                  fontWeight={item.fontWeight}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DescriptionBlock
